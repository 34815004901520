:root {
  --primary-color: #7ad077;
  --second-color: #E3EAF2;
}

html,
body {
	margin: 0;
	height: 100%;
	min-height: 100%;
	font-family: 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
	display: none;
}
body {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.main-component {
	/* position: absolute; */
	display: flex;
	flex-direction: row;
	align-items: stretch;
	width: 100%;
	/* height: 100%; */
}

.left-pane {
	flex: 1 1 0;
	padding: 20px;
}

.center-pane {
	flex: 1 1 0;
	padding: 20px;
}

.right-pane {
	flex: 1 1 0;
	padding: 20px;
}

hr.vertical {
	height: "100%";
	margin: 1px 0;
	padding: 1px;
}

.work-experience-container {
	display: flex;
	flex-direction: column;
}

.work-experience-header {
	flex: 3 1 0;
}

.work-experience-item {
	flex: 1 1 0;
	padding-left: 10px;
}

blockquote {
  background: rgba(77, 255, 124, 0.3);
  border-left: 10px solid var(--primary-color);
  margin: 1.5em 10px;
  padding: 1em 10px .1em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

a {
  color: inherit;
  font-weight: 500;
}

code {
  color: black;
  background-color: rgba(227, 234, 242);
}

@media only screen and (max-width: 1000px) {
	.main-component {
		flex-direction: column;
	}
}
