.article-wrapper {
	padding: 4rem;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.article-wrapper p {
	font-size: 11pt;
}

.article-wrapper h1, h2, h3 {
	font-weight: 400;
}

.article-wrapper header {
	margin-bottom: 50px;
}
